

div.google-account-linking-view {


}

div.google-account-linking-view.empty {
    display: none;
}

div.google-account-linking-view.error {

}

div.google-account-linking-view.error > span {

}
a.simple-button,
button.simple-button {
    font-family: Roboto,arial,sans-serif;
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.875rem;
    padding: 0.5em 1em;
}

a.simple-button.primary,
button.simple-button.primary {
    background-color: #0078e7;
    color: #fff;
}

button.simple-button.primary.pulse {
    animation-name: pulse-button;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes pulse-button {
    0% {
        background-color: #0078e7;
        border-color: #0078e7;
    }
    50% {
        background-color: #003a74;
        border-color: #003a74;
    }
    100% {
        background-color: #0078e7;
        border-color: #0078e7;
    }
}
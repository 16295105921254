.card > .header {
    background: black;
    margin: -2rem -2rem 2rem -2rem;

    /*min-height: 60px;*/
    display: flex;
    flex-direction: row;
    border-bottom: 0 solid transparent;
    padding: 6px 2rem 6px 2rem;
}

.altmetric .card > .header {
    background: #44454c;
}

.dimensions .card > .header {
    background: rgb(13, 70, 103);
}

.card > .header > .logo {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;

    padding: 1.25rem 0;
}

.card > .header > .logo img {
    width: 100%;
    /*height: 80px;*/
    height: 60px;
    object-fit: contain;
    object-position: center center;
}

.loading-holder {
    text-align: center;
}

.user-invite span.note {
    display: inline-block;
    margin-bottom: 2rem;
}

.user-invite > img.logo {
    width: 215px;
}

.user-invite .footer {
    margin-top: 2rem;
    font-size: 0.85rem;
    text-align: center;
    line-height: 1.5;
    color: #555;
    margin-bottom: -0.75rem;
}
.user-invite .footer > a,
.user-invite .footer > a:visited {
    color: #4091cc;
    text-decoration: none;
}


.user-invite > div.section-header {
    margin-left: 2px;
}

.user-invite p a,
.user-invite p a:visited {
    color: #4091cc;
    text-decoration: none;
}

.user-invite p.notice {
    color: #555;
}

.user-invite p.error {
    padding: 1rem;
    background: #ffdddb;
    border-radius: 0.5rem;
}

.user-invite p.error > b {
    font-size: 1rem;
    color: #86312b;
    display: block;
    margin-bottom: 0.5rem;
}

.user-invite p.extra {
    margin-bottom: 15rem;
}

.user-invite p.pre-extra {
    margin-bottom: 2rem !important;
}

.user-invite p.extra a {
    border: 1px solid #0078e7;
    background-color: #0078e7;
    color: white;
    border-radius: 3px;
    padding: 0.3rem 0.5rem;
    display: inline-block;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    box-sizing: border-box;
    margin-top: 0.5rem;
}

.user-invite p.extra a svg {
    height: 0.9rem;
    width: 0.9rem;
    fill: white;
    vertical-align: middle;
    margin-right: 0.25rem;
}


.confirm-section {
    text-align: center;
    margin-top: 2rem;
}

.confirm-section button {
    border-radius: 3px;
    border: 1px solid #0078e7;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    box-sizing: border-box;
}

.confirm-section span.waiting {
    display: flex;
    justify-content: center;
}

.confirm-section span.waiting > span {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-left: 0.2rem;
}

.details {
    padding: 1rem;
    margin-top: 1.5rem;
    background: white;
}


.details .detail-group {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.details .detail-group .label {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    color: #555;
}

.details > :first-child {
    margin-top: 0 !important;
    margin-left: 0 !important;
}
.details > :last-child {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}




/* Creating account in progress. */
.details.creating-account > * {
    opacity: 0.5;
    pointer-events: none;
}



.linked-account {
    display: inline-flex;
    border: 1px solid #0078e7;

    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    box-sizing: border-box;

}

.linked-account.removing {
    border: 1px solid #6a6a6a;
}

.linked-account .logo {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-right: 1px solid #0078e7;
    background: rgba(0, 120, 231, 0.1);
}

.linked-account.removing .logo {
    border-right: 1px solid #6a6a6a;
    background: rgba(0, 0, 0, 0.1);
}

.linked-account .logo .loader {
    width: 18px !important;
    height: 18px !important;
}

.linked-account .account {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
}

.linked-account .unlink {

}

.linked-account .unlink > button {
    border: none;
    border-left: 1px solid #0078e7;
    height: 100%;
}

.linked-account.removing .unlink > button {
    border: none;
    border-left: 1px solid #6a6a6a;
    background: #aaa;
}


.linked-account-error {
    font-size: 0.875rem;
    color: #555;
    background: #ffdddb;
    margin-top: 1rem;
    padding: 0.5rem 0.75rem;
}

.linked-account-error p {
    margin: 0;
}

.linked-account-error p + p {
    margin-top: 0.75rem;
}


.linked-account-error span {
    font-weight: bold;
}


.user-invite .create-account-holder {
    background: white;
    padding: 1rem;
}

.user-invite .legal-notice {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 0.9rem;
}

.user-invite .legal-notice span {
    font-style: italic;
}

.user-invite ul.product-listing {
    margin-bottom: 1.5rem;
}

.user-invite ul.product-listing li img {
    height: 1.25rem;
    vertical-align: bottom;
}

.user-invite ul.product-listing li + li {
    margin-top: 0.5rem;
}

.user-invite ul.product-listing .terms-of-use {
    font-size: 0.9rem;
    font-style: italic;
    opacity: 0.7;
}
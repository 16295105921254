a.google-associate-button {
    text-decoration: none;
}

.associate-holder {
    display: inline-flex;
    border: 1px solid #0078e7;

    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    box-sizing: border-box;
}

.associate-holder .logo {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-right: 1px solid #4285f4;
    background: white;
}

.associate-holder .logo .loader {
    width: 18px !important;
    height: 18px !important;
}

.associate-holder .button {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    background: #4285f4;
    color: white;
}


.associate-holder.in-progress {
    border: 1px solid #6a6a6a;
}

.associate-holder.in-progress .button {
    background: rgba(0, 0, 0, 0.1);
    color: #6a6a6a;
}

.associate-holder.in-progress .logo {
    border-right: 1px solid #6a6a6a;
}

div.pinning-project-info {
    margin-bottom: 2rem;
}


div.pinning-project-info img {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: block;
}

div.pinning-project-info span.show-details {
    color: #0078e7;
    text-decoration: underline dotted;
    text-underline: #0078e7;
    cursor: pointer;
    user-select: none;
}

div.pinning-project-info ul {
    background: white;
    padding: 1rem;
    padding-inline-start: revert;
}

div.product-info {
    margin-bottom: 2rem;
    padding: 0.75rem;
    border: 1px solid #bdbdbd;
    background: white;
    font-size: 0.9rem;
}

div.product-info div.project-id {
    margin-top: 1rem;
}

div.product-info img {
    width: 1rem;
    vertical-align: text-top;
}

div.product-info span.product-name {
    font-weight: bold;
}

div.product-info + div.product-info {
    margin-top: 2rem;
}

div.product-info a {
    border: 1px solid #0078e7;
    background-color: #0078e7;
    color: white;
    border-radius: 3px;
    padding: 0.3rem 0.5rem;
    display: inline-block;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    box-sizing: border-box;
    margin-top: 0.5rem;
}

div.product-info a svg {
    height: 0.9rem;
    width: 0.9rem;
    fill: white;
    vertical-align: middle;
    margin-right: 0.25rem;
}

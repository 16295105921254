body {
    font-family: Roboto,arial,sans-serif;
}

div.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

div.site-settings-load > div.user-invite {
    background-color: white !important;
}

div.site-error > div.user-invite {
    background-color: white !important;
}


.text-small {
    font-size: 0.875rem;
}

main, .text {
    font-size: 1rem;
}

.text-big {
    font-size: 1.1875rem;
}

h2 {
    line-height: 1.25;
    font-weight: 300;
    font-size: 2.1875rem;
}

span.note {
    font-style: italic;
    color: #444;
    font-size: 0.875rem;
}

p {
    line-height: 1.25;
}
